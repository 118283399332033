@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lexend';
  src: url('./fonts/readexpro-medium.woff2') format('woff2'),
    url('./fonts/readexpro-medium.woff') format('woff'),
    url('./fonts/readexpro-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}